import React from 'react';
import styles from './layout.module.scss'

interface Props {
  children: React.ReactNode;
}

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const WebFont = require('webfontloader');
  WebFont.load({
    typekit: {
      id: 'xdw1gts',
    },
  });
}

const Layout = ({ children }: Props) => (
  <React.Fragment>
    <div className={styles.container}>
      {children}
    </div>
  </React.Fragment>
);

export default Layout;
