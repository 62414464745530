import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import GatsbyImage from 'gatsby-image';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';

import styles from './index.module.scss';
import Footer from '../components/Footer';

const index = () => {
  const { site, washingtonImage } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      washingtonImage: file(name: {eq: "washington-censored"}) {
        childImageSharp {
          fixed(width: 1200) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = 'New Freedom City by Todd Dukart';

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta
          property="og:image:src"
          content={`${site.siteMetadata.siteUrl}${washingtonImage.childImageSharp.fixed.src}`}
        />
        <meta
          property="og:image:width"
          content={washingtonImage.childImageSharp.fixed.width}
        />
        <meta
          property="og:image:height"
          content={washingtonImage.childImageSharp.fixed.height}
        />
        <link
          href={`${site.siteMetadata.siteUrl}`}
          rel="canonical"
        />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Coming soon - New Freedom City by Todd Dukart" />
        <meta property="og:locale" content="en_US" />
        <meta property="twitter:creator" content="todddukart" />
      </Helmet>
      <div className={styles.washingtonImage}>
        <GatsbyImage
          alt="George Washington censored"
          fluid={washingtonImage.childImageSharp.fluid}
        />
      </div>
      <Footer>
        <p>
          &ldquo;George Washington&rdquo; by Gilbert Stuart
          {' '}
          <OutboundLink
            href="https://www.brooklynmuseum.org/opencollection/objects/934"
            target="_blank"
            rel="noopener noreferrer"
            style={{ border: 'none' }}
          >
            courtesy Brooklyn Museum
          </OutboundLink>
        </p>
      </Footer>
    </Layout>
  );
};

export default index;
