import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { D51MediaLogoHorizontalSmall } from '@mechninja/mn-components';
import styles from './footer.module.scss';

interface Props {
  children?: React.ReactNode;
}

const Footer = ({ children }: Props) => (
  <React.Fragment>
    <div className={styles.textFlex}>
      <div>
        New Freedom City
        <br />
        <div className={styles.authorName}>
          by
          {' '}
          <OutboundLink
            href="https://www.todddukart.com/author"
            target="_blank"
            rel="noopener noreferrer"
          >
            Todd Dukart
          </OutboundLink>
        </div>
      </div>
      <div>
        Coming Soon
      </div>
      <div>
        <OutboundLink
          href="https://www.d51media.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ border: 'none' }}
        >
          <D51MediaLogoHorizontalSmall color="white" height={13} />
        </OutboundLink>
      </div>
    </div>
    <div className={styles.credits}>
      {children}
      <p>
        This is a work of fiction. Names, characters, businesses, places, events, locales, and incidents are either the
        products of the author’s imagination or used in a fictitious manner. Any resemblance to actual persons, living
        or dead, or actual events is purely coincidental.
      </p>
      <p>
        Copyright &copy; 2019 Todd Dukart
      </p>
    </div>
  </React.Fragment>
);

export default Footer;
